import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnUnderline,
    Editor,
    EditorProvider,
    Toolbar,
} from 'react-simple-wysiwyg';

import {
    Button,
    Flex,
    Form,
    FormControl,
    Heading,
    Note,
    Paragraph,
    Skeleton,
    Switch,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { htmlToSlate } from '@slate-serializers/html';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { AppointmentMessage } from '../../../models';
import { HtmlToSlateConfigCustom } from '../../../utilities/html-to-slate-helper';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/appointment/message`;

const WelcomeMessageTypeId = 1;

export const WelcomeMessage: React.FC = () => {
    // Data domains
    const { runRequest } = useVetdeskMiddleware();

    // Component states
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState<AppointmentMessage[]>();

    // Form setup
    const {
        handleSubmit,
        formState: { errors },
        reset,
        register,
        setValue,
        trigger,
        control,
        watch,
    } = useForm();

    // Component defaults
    const isActive = watch('isActive');

    useEffect(() => {
        register('isActive');
        fetchMessages();
    }, []);

    const fetchMessages = () => {
        setIsLoading(true);
        setMessages(undefined);

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as AppointmentMessage[];

            const data = response.find(
                (x) => x.messageTypeId == WelcomeMessageTypeId
            );

            if (data) {
                reset(data);
                setMessages(response);
            } else {
                const defaultMessage: AppointmentMessage = {
                    title: '',
                    message: '',
                    isActive: true,
                    messageTypeId: WelcomeMessageTypeId,
                };

                reset(defaultMessage); // populate default

                response.push(defaultMessage);
                setMessages(response);
            }
            setIsLoading(false);
        });
    };

    const renderFields = () => {
        register('message', {
            required: 'This is required field',
        });
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Flex
                    flexDirection="column"
                    gap="spacingS"
                    justifyContent="center"
                >
                    <FormControl>
                        <FormControl.Label>
                            Title <Text fontColor="red500">*</Text>
                        </FormControl.Label>
                        <TextInput
                            placeholder="Enter a title for your welcome message"
                            {...register(`title`, {
                                required: 'This is required field',
                            })}
                        />
                        {errors.title && (
                            <Text fontColor="red500">
                                {errors.title.message?.toString()}
                            </Text>
                        )}
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>
                            Message <Text fontColor="red500">*</Text>
                        </FormControl.Label>
                        <Controller
                            name="message"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <EditorProvider>
                                    <Editor
                                        value={field.value}
                                        placeholder="Enter notes, terms and conditions, or special instruction here."
                                        onChange={(e) => {
                                            setValue('message', e.target.value);
                                            trigger('message');
                                        }}
                                    >
                                        <Toolbar>
                                            <BtnBold />
                                            <BtnItalic />
                                            <BtnUnderline />
                                            <BtnNumberedList />
                                            <BtnBulletList />
                                            <BtnLink />
                                        </Toolbar>
                                    </Editor>
                                </EditorProvider>
                            )}
                        />
                        {errors.message && (
                            <Text fontColor="red500">
                                {errors.message.message?.toString()}
                            </Text>
                        )}
                    </FormControl>
                </Flex>
            </Form>
        );
    };

    const onSubmit = async (data: any) => {
        const updatedMessages = messages?.map((msg) => {
            if (msg.messageTypeId === WelcomeMessageTypeId) {
                const updatedMessageSlate = JSON.stringify(
                    htmlToSlate(data.message, HtmlToSlateConfigCustom)
                );

                return {
                    ...data,
                    messageSlate: updatedMessageSlate,
                };
            }
            return msg;
        });

        await runRequest('PUT', API_URL, updatedMessages).then(
            (res) => {
                fetchMessages();
            },
            (error) => console.warn('Oops', error)
        );
    };

    return !isLoading ? (
        <>
            <Note style={{ marginBottom: 20 }}>
                Enable Welcome message only if essential, as this will create an
                extra step for users during booking.
            </Note>
            <Heading>Enable welcome message</Heading>
            <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                    <Switch isChecked={field.value} onChange={field.onChange}>
                        {field.value == true ? 'On' : 'Off'}
                    </Switch>
                )}
            />

            <Paragraph style={{ marginTop: 10 }}>
                The Welcome message will appear at the beginning of the booking
                process, serving as the first message users encounter.{' '}
            </Paragraph>
            {isActive && renderFields()}
            <Button
                size="small"
                variant="primary"
                onClick={() => handleSubmit(onSubmit)()}
            >
                Save
            </Button>
        </>
    ) : (
        <Skeleton.Container>
            <Skeleton.BodyText numberOfLines={4} />
        </Skeleton.Container>
    );
};
