import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import {
    BtnBold,
    BtnBulletList,
    BtnItalic,
    BtnLink,
    BtnNumberedList,
    BtnUnderline,
    Editor,
    EditorProvider,
    Toolbar,
} from 'react-simple-wysiwyg';

import {
    Button,
    Flex,
    FormControl,
    Heading,
    Modal,
    ModalConfirm,
    Paragraph,
    Select,
    Stack,
    Switch,
    Text,
    Textarea,
    TextInput,
} from '@contentful/f36-components';
import { PlusIcon } from '@contentful/f36-icons';
import { htmlToSlate } from '@slate-serializers/html';

import { ENV } from '../../../environments';
import { useVetdeskMiddleware } from '../../../hooks';
import { personalisationTokens } from '../../../locations/page-auto-reminders';
import { AppointmentType } from '../../../models';
import { HtmlToSlateConfigCustom } from '../../../utilities/html-to-slate-helper';
import { TableList } from '../../table-list';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/reminder`;
const API_Reminder_Type_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/appointment/appointment-type`; // replace url to reminder types

interface GeneralAppointmentReminder {
    id: string;
    name: string;
    appointmentTypes: string[];
    scheduleFrequency: number;
    schedulePeriodType: number;
    schedulePeriodTypeName: string;
    scheduleSummary: string;
    isActive: boolean;
    reminderType: number;
    reminderTypeName: string;
    message?: string;
    messageSlate: string;
}

const REMINDER_COLUMNS = [
    { label: 'Reminder name', type: 'string', field: 'name' },
    { label: 'Status', type: 'badgeActiveInactive', field: 'isActive' },
    { label: 'Scheduled', type: 'string', field: 'scheduleSummary' },
    { label: 'Message type', type: 'string', field: 'reminderTypeName' },
];

interface SelectionState {
    start: number;
    end: number;
}

export const GeneralAppointmentReminders: React.FC = () => {
    const [selection, setSelection] = useState<SelectionState>({
        start: 0,
        end: 0,
    });
    const [editItem, setEditItem] = useState<GeneralAppointmentReminder | null>(
        null
    );
    const [itemToDelete, setItemToDelete] =
        useState<GeneralAppointmentReminder | null>(null);
    const [list, setList] = useState<GeneralAppointmentReminder[] | undefined>(
        undefined
    );
    const [appointmentTypeList, setAppointmentTypeList] = useState<
        AppointmentType[]
    >([]);

    const { runRequest } = useVetdeskMiddleware();

    const fetchList = () => {
        setList(undefined); // to display skeleton when fetching

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as GeneralAppointmentReminder[];
            setList(response);
        });
    };

    const fetchAppointmentTypes = () => {
        runRequest('GET', `${API_Reminder_Type_URL}`).then((res) => {
            const response = res as AppointmentType[];
            setAppointmentTypeList(response);
        });
    };

    useEffect(() => {
        fetchList();
        fetchAppointmentTypes();
    }, []);

    const {
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        getValues,
        trigger,
        control,
    } = useForm();

    const handleUpsert = (item?: any) => {
        reset(item);
        setEditItem(item);
    };

    const onModalClose = () => {
        reset({});
        setEditItem(null);
    };

    const prepareCreate = () => {
        const defaultReminder: GeneralAppointmentReminder | null = {
            id: '',
            name: '',
            appointmentTypes: [],
            scheduleFrequency: 1,
            schedulePeriodType: 0,
            schedulePeriodTypeName: '',
            scheduleSummary: '',
            isActive: false,
            reminderType: 0,
            reminderTypeName: '',
            message: undefined,
            messageSlate: '',
        };
        setEditItem(defaultReminder);
        // id for create
        const formData = { ...defaultReminder, id: undefined };
        reset(formData);
    };

    const onSubmit = async (data: any) => {
        console.log('submitted data', data);
        data.messageSlate = JSON.stringify(
            htmlToSlate(data.message, HtmlToSlateConfigCustom)
        );

        if (data.id) {
            // edit
            await runRequest('PUT', `${API_URL}/${data.id}`, data).then(
                (res) => {
                    fetchList();
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        } else {
            // create
            await runRequest('POST', `${API_URL}`, data).then(
                (res) => {
                    fetchList();
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    const onDelete = async () => {
        if (itemToDelete) {
            await runRequest('DELETE', `${API_URL}/${itemToDelete.id}`).then(
                (res) => {
                    fetchList();
                    setItemToDelete(null);
                    setEditItem(null);
                },
                (error) => console.warn('Oops', error)
            );
        }
    };

    watch('reminderType');

    const message = watch('message', '');

    // Handle token insertion
    const handleTokenSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const token = event.target.value;

        if (!token) return; // If no token selected, do nothing

        const textarea = document.getElementById(
            'personalizationTextarea'
        ) as HTMLTextAreaElement | null;

        if (textarea) {
            const { selectionStart, selectionEnd } = textarea;

            // Insert token at the current cursor position in the textarea
            const newText =
                message.substring(0, selectionStart) +
                token +
                message.substring(selectionEnd);

            // Update the textarea value with the new text
            setValue('message', newText);

            // Reset the dropdown after inserting the token
            event.target.value = '';
        }
    };

    const handleTokenSelectForEditor = (event: any) => {
        const token = event.target.value;

        if (!token) return; // If no token selected, do nothing
        console.log('selection', selection);
        const { start, end } = selection;
        // Insert token at the current cursor position in the textarea
        const newText =
            message.substring(0, start) +
            `<span>${token}</span>` +
            message.substring(end);

        console.log('newText', newText);

        // Update the textarea value with the new text
        setValue('message', newText);

        // Reset the dropdown after inserting the token
        event.target.value = '';
    };

    // Replace tokens with actual values
    // const processText = () => {
    //     let processedText = message;
    //     (
    //         Object.keys(personalisationTokens) as Array<
    //             keyof typeof personalisationTokens
    //         >
    //     ).forEach((token) => {
    //         processedText = processedText.replaceAll(
    //             token,
    //             personalisationTokens[token]
    //         );
    //     });
    //     return processedText;
    // };

    const handleEditorSelect = useCallback(() => {
        const selectionObj = window.getSelection();
        if (!selectionObj || selectionObj.rangeCount === 0) return;

        const range = selectionObj.getRangeAt(0);
        let startOffset = 0;
        let endOffset = 0;
        const currentNode: Node | null = range.startContainer;

        // Helper function to traverse previous siblings and sum up text length
        const traverseSiblings = (node: Node) => {
            while (node.previousSibling) {
                node = node.previousSibling;
                startOffset += node.textContent?.length || 0;
                endOffset += node.textContent?.length || 0;
            }
        };

        // Calculate start and end offsets
        traverseSiblings(currentNode);
        startOffset += range.startOffset;
        endOffset += range.endOffset;

        setSelection({
            start: startOffset,
            end: endOffset,
        });
    }, []);

    // Add listeners for mouse and keyboard events to capture selections
    useEffect(() => {
        document.addEventListener('mouseup', handleEditorSelect);
        document.addEventListener('keyup', handleEditorSelect);

        return () => {
            document.removeEventListener('mouseup', handleEditorSelect);
            document.removeEventListener('keyup', handleEditorSelect);
        };
    }, [handleEditorSelect]);

    const renderMessageContent = (type: number | string) => {
        type = String(type);
        switch (type) {
            case '1': // Email
                return (
                    <>
                        <FormControl>
                            <FormControl.Label>
                                Subject line <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        {...field}
                                        placeholder="Enter text"
                                    />
                                )}
                            />
                            {errors.subject && (
                                <Text fontColor="red500">
                                    {errors.subject.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>
                                Message <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Controller
                                name="message"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <EditorProvider>
                                        <Editor
                                            id="personalizationTextarea"
                                            value={field.value}
                                            placeholder="Enter message"
                                            onChange={(e) => {
                                                setValue(
                                                    'message',
                                                    e.target.value
                                                );
                                                trigger('message');
                                            }}
                                            onMouseUp={handleEditorSelect} // Trigger on mouse up
                                            onKeyUp={handleEditorSelect} // Trigger on key up
                                        >
                                            <Toolbar>
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnUnderline />
                                                <BtnNumberedList />
                                                <BtnBulletList />
                                                <BtnLink />
                                                <Select
                                                    size="small"
                                                    style={{
                                                        minWidth: 200,
                                                    }}
                                                    onChange={
                                                        handleTokenSelectForEditor
                                                    }
                                                >
                                                    <Select.Option value="">
                                                        Personalisation tokens
                                                    </Select.Option>
                                                    {Object.keys(
                                                        personalisationTokens
                                                    ).map((token) => (
                                                        <Select.Option
                                                            key={token}
                                                            value={token}
                                                        >
                                                            {
                                                                personalisationTokens[
                                                                    token as keyof typeof personalisationTokens
                                                                ]
                                                            }
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Toolbar>
                                        </Editor>
                                    </EditorProvider>
                                )}
                            />
                            {errors.message && (
                                <Text fontColor="red500">
                                    {errors.message.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                    </>
                );

            case '2': // SMS
                return (
                    <FormControl>
                        <Flex justifyContent="space-between">
                            <FormControl.Label>
                                Message <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Select
                                size="small"
                                style={{ minWidth: 200, marginBottom: 10 }}
                                onChange={handleTokenSelect}
                            >
                                <Select.Option value="">
                                    Personalisation tokens
                                </Select.Option>
                                {Object.keys(personalisationTokens).map(
                                    (token) => (
                                        <Select.Option
                                            key={token}
                                            value={token}
                                        >
                                            {
                                                personalisationTokens[
                                                    token as keyof typeof personalisationTokens
                                                ]
                                            }
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                        </Flex>

                        <Controller
                            name="message"
                            control={control}
                            rules={{
                                required: 'This field is required',
                            }}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    id="personalizationTextarea"
                                    placeholder="Enter text"
                                />
                            )}
                        />
                        {errors.message && (
                            <Text fontColor="red500">
                                {errors.message.message?.toString()}
                            </Text>
                        )}
                    </FormControl>
                );
            case '3': // APP
                return (
                    <>
                        <FormControl>
                            <FormControl.Label>
                                Subject line <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        {...field}
                                        placeholder="Enter text"
                                    />
                                )}
                            />
                            {errors.subject && (
                                <Text fontColor="red500">
                                    {errors.subject.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <Flex justifyContent="space-between">
                                <FormControl.Label>
                                    Message <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Select
                                    size="small"
                                    style={{ minWidth: 200, marginBottom: 10 }}
                                    onChange={handleTokenSelect}
                                >
                                    <Select.Option value="">
                                        Personalisation tokens
                                    </Select.Option>
                                    {Object.keys(personalisationTokens).map(
                                        (token) => (
                                            <Select.Option
                                                key={token}
                                                value={token}
                                            >
                                                {
                                                    personalisationTokens[
                                                        token as keyof typeof personalisationTokens
                                                    ]
                                                }
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Flex>
                            <Controller
                                name="message"
                                control={control}
                                rules={{
                                    required: 'This field is required',
                                }}
                                render={({ field }) => (
                                    <Textarea
                                        {...field}
                                        id="personalizationTextarea"
                                        placeholder="Enter text"
                                    />
                                )}
                            />
                            {errors.message && (
                                <Text fontColor="red500">
                                    {errors.message.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                    </>
                );
            default:
                return <></>;
        }
    };

    const renderEditModel = () => {
        return (
            <Modal
                size="800px"
                onClose={onModalClose}
                isShown={editItem != null}
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title={
                                getValues('id')
                                    ? 'Edit reminder'
                                    : 'Create reminder'
                            }
                            onClose={onModalClose}
                        />
                        <Modal.Content>
                            <FormControl>
                                <FormControl.Label>Status</FormControl.Label>
                                <Controller
                                    name="isActive"
                                    control={control}
                                    render={({ field }) => (
                                        <Switch
                                            isChecked={field.value}
                                            onChange={field.onChange}
                                        >
                                            {field.value == true ? 'On' : 'Off'}
                                        </Switch>
                                    )}
                                />
                                <Paragraph>
                                    Toggle on/off to activate or deactivate
                                    reminders without losing any information.
                                </Paragraph>
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Reminder name
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            {...field}
                                            placeholder="Enter text"
                                        />
                                    )}
                                />
                                {errors.name && (
                                    <Text fontColor="red500">
                                        {errors.name.message?.toString()}
                                    </Text>
                                )}
                                <Paragraph>
                                    This will be shown in the table to help you
                                    distinguish.
                                </Paragraph>
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Reminder type{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    control={control}
                                    name="appointmentTypes"
                                    render={({
                                        field: { onChange, value },
                                    }) => {
                                        const options =
                                            appointmentTypeList?.map((x) => ({
                                                value: x.id,
                                                label: x.name,
                                            }));
                                        return (
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <ReactSelect
                                                    value={options.filter((c) =>
                                                        value?.includes(c.value)
                                                    )}
                                                    onChange={(val) => {
                                                        const values = val.map(
                                                            (c) => c.value
                                                        );
                                                        onChange(values);
                                                    }}
                                                    options={options}
                                                    isMulti
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                {errors.appointmentTypes && (
                                    <Text fontColor="red500">
                                        {errors.appointmentTypes.message?.toString()}
                                    </Text>
                                )}
                            </FormControl>

                            <FormControl>
                                <FormControl.Label>
                                    Message type{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Controller
                                    name="reminderType"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            style={{ minWidth: 100 }}
                                        >
                                            <Select.Option value="1">
                                                Email
                                            </Select.Option>
                                            <Select.Option value="2">
                                                SMS
                                            </Select.Option>
                                            <Select.Option value="3">
                                                App notification
                                            </Select.Option>
                                        </Select>
                                    )}
                                />
                                <Paragraph>
                                    This will be shown in the table to help you
                                    distinguish.
                                </Paragraph>
                            </FormControl>
                            {getValues('reminderType') &&
                                renderMessageContent(getValues('reminderType'))}
                            <FormControl>
                                <FormControl.Label>
                                    How prior would you like to send the
                                    reminder? <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Stack>
                                    <Controller
                                        name="scheduleFrequency"
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                style={{ minWidth: 100 }}
                                            >
                                                <Select.Option value="1">
                                                    1
                                                </Select.Option>
                                                <Select.Option value="2">
                                                    2
                                                </Select.Option>
                                                <Select.Option value="3">
                                                    3
                                                </Select.Option>
                                            </Select>
                                        )}
                                    />

                                    <Controller
                                        name="schedulePeriodType"
                                        control={control}
                                        rules={{
                                            required: 'This field is required',
                                        }}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                style={{ minWidth: 100 }}
                                            >
                                                <Select.Option value="2">
                                                    Days
                                                </Select.Option>
                                                <Select.Option value="3">
                                                    Weeks
                                                </Select.Option>
                                                <Select.Option value="4">
                                                    Months
                                                </Select.Option>
                                            </Select>
                                        )}
                                    />
                                </Stack>
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Stack>
                                <Button
                                    size="small"
                                    variant="negative"
                                    onClick={() => {
                                        setItemToDelete(editItem);
                                    }}
                                >
                                    Delete
                                </Button>

                                <Button
                                    size="small"
                                    variant="transparent"
                                    onClick={onModalClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    size="small"
                                    variant="positive"
                                    onClick={() => handleSubmit(onSubmit)()}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Modal.Controls>
                        <ModalConfirm
                            title="Delete reminder"
                            intent="negative"
                            isShown={itemToDelete != null}
                            onCancel={() => {
                                setItemToDelete(null);
                            }}
                            onConfirm={onDelete}
                            confirmLabel="Delete"
                        >
                            <Text>
                                Do you really want to delete this reminder?
                            </Text>
                        </ModalConfirm>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <div>
                    <Heading>General</Heading>
                    <Paragraph>Explanation about this reminder.</Paragraph>
                </div>

                <Button
                    startIcon={<PlusIcon />}
                    variant="primary"
                    size="small"
                    onClick={prepareCreate}
                >
                    Add new reminder
                </Button>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TableList<GeneralAppointmentReminder>
                    columns={REMINDER_COLUMNS}
                    items={list}
                    onEdit={handleUpsert}
                />
            </Flex>
            {renderEditModel()}
        </>
    );
};
